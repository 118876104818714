// SLIDER
const swiper = new Swiper('.swiper-container', {
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  speed: 1000,
  effect: 'coverflow',
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: 1,
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows : true
  },
  loop: true,
  breakpoints: {
    768: {
      centeredSlides: true,
      slidesPerView: 2,
      spaceBetween: 0
    }
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  }
});
